import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '@/views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Hi.vue')
  },
  /*
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/hi',
    name: 'Hi',
    component: () => import('@/views/Hi.vue')
  },
  {
    path: '/news',
    name: 'News',
    component: () => import('@/views/NewsView.vue')
  },
  {
    path: '/shows',
    name: 'Shows',
    component: () => import('@/views/ShowsView.vue')
  },
  {
    path: '/show/:showId',
    name: 'Show',
    component: () => import('@/views/ShowView.vue')
  },
  {
    path: '/music',
    name: 'Music',
    component: () => import('@/views/MusicView.vue')
  },
  */
  {
    path: '*',
    redirect: '/'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
